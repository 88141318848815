var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isGuest
    ? _c("footer", { staticClass: "mas-footer" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "d-flex flex-column" },
            [
              _c("manager", { staticClass: "only-desktop" }),
              _vm._v(" "),
              _c("manager", {
                staticClass: "only-mobile mobile",
                attrs: { mobile: true },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.instruction
          ? _c("div", { staticClass: "link" }, [_vm._m(0)])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c(
        "a",
        { attrs: { href: "/doc/common_instruction.pdf", target: "_blank" } },
        [_vm._v("Инструкция по работе с MAS")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
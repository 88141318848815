var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout" }, [
    _c(
      "div",
      { staticClass: "fake-vue-body", attrs: { id: "g-mas-pageContent" } },
      [
        _c("LayoutHeader", { attrs: { "on-index": _vm.onIndex } }),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex flex-row middle-area" }, [
          _c(
            "div",
            { staticClass: "container-fluid flex-grow-1 pb-10 pt-3 pl-4 pr-4" },
            [
              _vm.back
                ? _c("div", { staticClass: "mt-3" }, [
                    _c(
                      "span",
                      { on: { click: _vm.goBack } },
                      [
                        _c("fa-icon", { attrs: { icon: "angle-left" } }),
                        _vm._v(" Назад"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pageTitle mt-4 mb-4" },
                [
                  _vm._t("title", function () {
                    return [_c("h1", [_vm._v(_vm._s(_vm.title))])]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm._t("default"),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("modal-message"),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loaderShow,
                expression: "loaderShow",
              },
            ],
            attrs: { id: "preloader" },
          },
          [_c("div", { attrs: { id: "loader" } })]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [_c("LayoutFooter")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }